
import React, { Suspense } from 'react';
import { DISCORD_URL, FACEBOOK_URL, YOUTUBE_URL } from '../../../parametros';
import RouteConfig from '../../../routeConfig';
import { buscarTotalDeContas } from '../../services/busca-total-de-contas';
import { buscarTotalDePersonagens } from '../../services/busca-total-de-personagens';
import { MenuComponent } from '../index-component/menu-component/menu-component';
import { RodapeComponent } from '../rodape-component/rodape.component';
import './main.component.scss';
import { RouterProvider } from 'react-router-dom';
import router from '../../../routeConfig';

export default class MainComponent extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            totalContas: 0,
            totalPersonagens: 0,

        }
    };

    componentDidMount() {

        let self = this;

        buscarTotalDeContas().then(function (data) {
            self.setState({ totalContas: data });
        }).catch(function () {
            alert('Falha ao buscar total de contas!');
        });

        buscarTotalDePersonagens().then(function (data) {
            self.setState({ totalPersonagens: data });
        }).catch(function () {
            alert('Falha ao buscar total de personagens!');
        });
    }

    componentWillMount() {
    }

    irAteFacebook() {
        window.open(FACEBOOK_URL);
    }

    irAteYoutube() {
        window.open(YOUTUBE_URL);
    }

    irAteDiscord() {
        window.open(DISCORD_URL);
    };

    irAteCanalDoYoutube() {
        window.open(YOUTUBE_URL);
    }

    render() {

        let { totalContas, totalPersonagens } = this.state;

        return (
            <div>

                <div className={'container main-component'}>

                    <div className={'row conteudo-principal'}>
                        <div className={'col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'}>
                            <div className={'situacao-do-jogo text-center col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'}>

                                <div className={'container-infos-bd'}>
                                    <img src={'./assets/Imagens/logo.png'} className={'img-fluid img-responsive logo'} />
                                    <br /> <br />
                                    <h5 className={'text-center'}>Situação Atual Do Jogo</h5>
                                    <span className={'online'} style={{ fontFamily: 'Cursive' }}>Online (Alfa v1.7)</span>
                           
                                </div>
                            </div>

                            <div className={'informacoes-do-jogo text-center col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'}>

                                <div className={'container-infos-bd'}>
                                    <h5 className={'text-center'}>Informações Do Jogo</h5>

                                    <span className={'total-de-algo'}>Contas Criadas: <span className={'cor-resultado'}>{totalContas}</span></span>
                                    <span className={'total-de-algo'}>Personagens Criados: <span className={'cor-resultado'}>{totalPersonagens}</span></span>
                                </div>

                            </div>

                            <div className={'informacoes-do-jogo conteudos-menu text-center col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'}>

                                <h5 className={'text-center'}>Comunidades Do Jogo</h5>
                                <button onClick={this.irAteFacebook.bind(this)} className={'btn-menu col-12 col-sm-12 col-md-11 col-lg-11 col-xl-9'}>Facebook <img style={{ marginTop: '-5px' }} src={'./assets/Imagens/icone-facebook.png'} className={'img-fluid img-responsive icone'} /></button>
                                <button onClick={this.irAteYoutube.bind(this)} className={'btn-menu  col-12 col-sm-12 col-md-11 col-lg-11 col-xl-9'}>Youtube <img style={{ marginTop: '-5px' }} src={'./assets/Imagens/icone-youtube.png'} className={'img-fluid img-responsive icone'} /></button>
                                <button onClick={this.irAteDiscord.bind(this)} className={'btn-menu  col-12 col-sm-12 col-md-11 col-lg-11 col-xl-9'}>Discord <img style={{ marginTop: '-5px' }} src={'./assets/Imagens/icone-discord.png'} className={'img-fluid img-responsive icone'} /></button>
                            </div>

                            <div className={'informacoes-do-jogo text-center col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'}>
                                {/* <h5 className={'text-center'}>Sobre O Jogo</h5> */}
                                <hr />
                                {/* <button onClick={this.irAteCanalDoYoutube.bind(this)} className={'btn btn-menu form-control col-12 col-sm-12 col-md-11 col-lg-11 col-xl-9'}>Jogo em desenvolvimento</button> */}
                            </div>
                        </div>
                        <div className={'col-12 col-sm-12 col-md-12 co-lg-8 col-xl-8'}>

                            <div className={'row'}>
                                <div className={'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'}>
                                    <MenuComponent />
                                </div>
                            </div>

                            <br />

                            <Suspense fallback={''}  >
                                <RouterProvider router={router} />
                            </Suspense>
                        </div>
                    </div>

                </div>

                <RodapeComponent />

            </div >
        )
    }
};