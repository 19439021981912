
 
import './rodape.component.scss';

const RodapeComponent = () => {
    return (
        <footer className={'rodape-component rodape-component--style'}>
            <span>Dreams Stories Online MMORPG - Game Indie E Brasileiro - Em desenvolvimento por Donovan Sousa - @2021 ~ 2024 - Todos os direitos reservados.</span>
        </footer>
    )
};

export {
    RodapeComponent
};