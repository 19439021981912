
import './menu-component.scss';

const MenuComponent = () => {

    function irAtePaginaLojaDePontos() {
        window.location.href = "#/loja";
    }

    function irAteAPaginaDeDownload() {
        window.location.href = "#/download";
    };

    function irAtePaginaDeRegistro() {
        window.location.href = "#/registro";
    };

    function irAtePaginaPrincipal() {
        window.location.href = "#/";
    };

    return (
        <header className={'menu-component container'}>

            <div className={'menu-opcoes row'}>
                <div className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3'}>  <button onClick={irAtePaginaPrincipal.bind(this)} className={'btn-menu form-control'}><span>Inicio</span></button></div>
                <div className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3'}>  <button onClick={irAtePaginaDeRegistro.bind(this)} className={'btn-menu form-control'}><span>Criar Conta</span></button></div>
                <div className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3'}>  <button onClick={irAteAPaginaDeDownload.bind(this)} className={'btn-menu form-control'}><span>Download</span></button></div>
                <div className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3'}>  <button onClick={irAtePaginaLojaDePontos.bind(this)} className={'btn-menu form-control'}><span>Loja De Pontos</span></button></div>
            </div>
        </header>
    );
};

export {
    MenuComponent
}