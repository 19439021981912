import { BASE_END_POINT } from "../../parametros";

function buscarTotalDePersonagens(): Promise<any> {

    return new Promise(async function (sucesso, rejeitado) {

        let url = `${BASE_END_POINT}/api-v1/comunicacao/totalpersonagens`;
        let response = await fetch(url, { headers: { "Access-Control-Allow-Origin": "*"} });

        if (response.status == 200) {
            sucesso(response.json());
        }
        else {
            rejeitado(response.status);
        }

    });
};

export {
    buscarTotalDePersonagens
}