
import React, { lazy, Suspense } from 'react';

import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    Routes,

    createHashRouter,
} from "react-router-dom";

// import IndexComponent from './app-site/components/index-component/index.component';
// import RegistroComponent from './app-site/components/registro-component/registro.component';
// import DownloadComponent from './app-site/components/download-component/download-component';


const IndexComponent = React.lazy(() => import('./app-site/components/index-component/index.component'));
const RegistroComponent = React.lazy(() => import('./app-site/components/registro-component/registro.component'));
const DownloadComponent = React.lazy(() => import('./app-site/components/download-component/download-component'));
const LojaComponent = React.lazy(() => import('./app-site/components/loja-component/loja-component'));

const router = createHashRouter([
    {
        path: "/",
        element: <IndexComponent />,
    },
    {
        path: "/registro",
        element: <RegistroComponent />,
    },
    {
        path: "/download",
        element: <DownloadComponent />,
    },
    {
        path: "/loja",
        element: <LojaComponent />,
    },
]);


export default router;
