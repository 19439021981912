
const FACEBOOK_URL: string = 'https://www.facebook.com/Dreams-Stories-Online-Mmorpg-101113088504586';
const DISCORD_URL: string = 'https://discord.gg/GjWSpMfa';
const YOUTUBE_URL: string = 'https://www.youtube.com/channel/UCAztI2wkLbFIVaur7-FG9yQ';
const BASE_END_POINT: string = 'https://dreamsstories-api-site.azurewebsites.net';

export {
    FACEBOOK_URL,
    DISCORD_URL,
    YOUTUBE_URL,
    BASE_END_POINT,
}